<template>
    <div>
        <b-navbar
            variant="transparent"
            class="navbar navbar-shadow justify-content-between align-items-center"
            style="min-height: 4.45rem"
        >
            <b-nav class="mb-0 rounded" tabs>
                <b-nav-item
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :active="tab.active"
                    @click="switchComponent(index)"
                >
                    {{ $t(tab.label) }}
                </b-nav-item>
            </b-nav>
        </b-navbar>

        <KeepAlive>
            <transition name="zoom-fade" mode="out-in">
                <component :is="currentComponent" />
            </transition>
        </KeepAlive>
    </div>
</template>

<script>
import customerOverviewTab from '@/@core/components/accountingData/customer/customerOverviewTab.vue';
import customerSegmentsTab from '@/@core/components/accountingData/customer/customerSegmentsTab.vue';

export default {
    name: 'CustomersView',
    components: {customerOverviewTab, customerSegmentsTab},
    data() {
        return {
            currentComponent: 'customerOverviewTab',
            tabs: [
                {
                    label: 'CustomersCard.customerOverviewTab',
                    active: true,
                    component: 'customerOverviewTab',
                },
                {
                    label: 'CustomersCard.customerSegmentsTab',
                    active: false,
                    component: 'customerSegmentsTab',
                },
            ],
            locale: {
                tabs: 'CustomersCard',
            },
        };
    },
    methods: {
        switchComponent(tabIndex) {
            this.tabs.find(item => item.active == true).active = false;

            this.currentComponent = this.tabs[tabIndex].component;

            this.tabs[tabIndex].active = true;
        },
    },

    mounted() {},
};
</script>

<style></style>
