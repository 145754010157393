<template>
  <div>
    <div class="mt-2">
      <b-container fluid>
        <b-row align-v="center" class="mb-2">
          <b-col cols="6">
            <p class="h4 d-block d-lg-none mb-0" v-text="$t(locale.title)"/>

            <p class="h3 d-none d-lg-block mb-0" v-text="$t(locale.title)"/>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <b-button
                v-ripple.400
                variant="secondaryColor"
                @click="$router.push({ name: 'acountingCustomerSegmentCreate' })"
                v-text="$t(locale.addSegment)"
            />
          </b-col>
        </b-row>

        <b-row align-h="end">
          <b-col cols="6" class="d-flex justify-content-end mb-2">
            <div class="d-flex align-items-center bigGap">
              <b-form-input
                  v-model="search"
                  @input="searchCustomerSegment"
                  placeholder="Sök"
              />

              <img
                  role="button"
                  src="@/assets/icon/menuIcon.png"
                  width="20px"
              />

              <feather-icon role="button" icon="ListIcon" size="40"/>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
              v-for="data in segmentResults.results"
              :key="data.uuid"
              cols="12"
              sm="6"
              lg="4"
          >
            <b-card class="">
              <b-card-title
                  class="d-flex mb-0 justify-content-between align-items-center"
              >
                <p class="mb-0">{{ data.name }}</p>

                <div class="d-flex smallGap">
                  <span @click="openDeleteModal(data)">
                    <mdicon
                        class="text-danger"
                        role="button"
                        :width="20"
                        :height="20"
                        name="trash-can"
                    />
                  </span>
                  <b-button
                      size="sm"
                      variant="outline-secondaryColor"
                      block
                      @click="
                      $router.push({
                        name: 'acountingCustomerSegmentEdit',
                        params: { id: data.uuid },
                      })
                    "
                      v-text="$t(locale.edit)"
                  />
                </div>
              </b-card-title>
              <hr/>
              <b-card-text class="mt-1">
                <div class="d-flex justify-content-between">
                  <p class="mb-0 w-50 text-secondaryColor" id="label">
                    {{ $t(locale.description) }}
                  </p>
                  <p class="mb-0 w-50">
                    {{ data.description }}
                  </p>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <p class="mb-0 w-50 text-secondaryColor" id="label">
                    {{ $t(locale.numberOfCustomers) }}
                  </p>
                  <p class="mb-0 w-50">
                    Består av 
                    {{ data.customers.length }} kunder
                  </p>
                </div>

                <hr/>

                <div class="d-flex justify-content-between">
                  <p class="mb-0 w-50 text-secondaryColor" id="label">
                    {{ $t(locale.recurringBilling) }}
                  </p>
                  <p class="mb-0 w-50">
                    Detta segment faktureras återkommande totalt
                    {{ data.recurring_billing }}
                    per månad
                  </p>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <div class="mt-5">
          <div class="w-full flex justify-end">
            <paginate
                v-model="currentPage"
                :page-count="segmentResults.total_pages"
                :click-handler="firePagination"
                :prev-text="'<'"
                :next-text="'>'"
                :page-class="'page-item c-mr-1 border-unset'"
                prev-link-class="page-link c-mr-1 border-unset"
                next-link-class="page-link c-mr-1 border-unset"
                next-class="next-item"
                prev-class="prev-item"
                page-link-class="page-link c-mr-1 border-unset"
                active-class="active c-mr-1 border-unset"
                :container-class="'pagination  b-pagination'"
            >
            </paginate>
          </div>
        </div>
      </b-container>
    </div>

    <Modal
        id="modal-remove-segment"
        :hideFooter="true"
        :title="'Remove Customer Segment'"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          Är du säker på att du vill radera kundsegmentet?
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-remove-segment')"
        >
          <span v-text="$t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="removeSegment"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') : $t('Message.proceed')"
          />
        </b-button>
      </div>
    </Modal>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import Paginate from "vuejs-paginate";
import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: "customerSegmentsTab",
  components: {
    paginate: Paginate, accessDenied
  },
  data() {
    return {
      search: "",
      params: {},
      selectedData: {},
      sendingModalAction: false,
      segmentResults: {results: [], total_pages: 1},
      currentPage: 1,
      locale: {
        title: "CustomersCard.customerSegmentsTab",
        addSegment: "CustomersCard.addSegment",
        edit: "edit",
        description: 'segmentDescription',  
        averageEarning: "segmentAverageEarning",
        recurringBilling: "segmentReccuringBilling",
        numberOfArticles: "articleSegmentNumberOfArticles",
        numberOfCustomers: "articleSegmentNumberOfCustomers"
      },
    };
  },

  methods: {
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    getCustomersSegment() {
      this.$useJwt
          .customSegment(
              {URL: "/customers", method: "get"},
              {params: this.params}
          )
          .then((res) => {
            if (res.data.total_pages) {
              this.segmentResults = res.data;
            } else {
              this.segmentResults = {
                ...this.segmentResults,
                results: res.data.results,
              };
            }
          }).catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      });
    },
    searchCustomerSegment: debounce(function (e) {
      if (e) {
        this.params.search = e;
      } else {
        delete this.params.search;
      }
      this.getCustomersSegment();
    }, 350),
    firePagination(number) {
      this.currentPage = number;
      this.params.page = number;
      this.getCustomersSegment();
    },
    openDeleteModal(data) {
      this.selectedData = data;
      this.$bvModal.show("modal-remove-segment");
    },
    removeSegment() {
      this.sendingModalAction = true;
      this.$useJwt
          .customSegment({
            URL: `/customers/${this.selectedData.uuid}`,
            method: "delete",
          })
          .then((res) => {
            this.getCustomersSegment();
            this.sendingModalAction = false;
            this.$bvModal.hide("modal-remove-segment");

            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.sendingModalAction = false;
            if (
                err.response.data &&
                err.response.data &&
                err.response.data.message
            ) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data.message.ErrorInformation.message,
                    "AlertTriangleIcon",
                    "danger"
                );
              } else {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data.message,
                    "AlertTriangleIcon",
                    "danger"
                );
              }
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getCustomersSegment();
  },
};
</script>

<style></style>
