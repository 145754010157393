<template>
  <div>
    <div class="mt-2">
      <b-card>
        <!-- <Loading v-if="loadingState" /> -->
        <b-card-text>
          <b-row>
            <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center"
            >
              <p class="mb-0">
                <span
                    class=""
                    style="margin-right: 5px"
                    v-text="totalCustomer"
                />

                <span v-text="$t($route.meta.pageTitle)"/>
              </p>

              <b-button
                  @click="$router.push({ name: 'acountingCustomerCreate' })"
                  v-ripple.400
                  variant="secondaryColor"
                  v-text="$t(locale.addCustomer)"
              />
            </b-col>

            <b-col cols="12" class="mt-1">
              <b-row>
                <b-col cols="12" lg="5">
                  <div class="d-flex smallGap">
                    <b-button
                        v-for="(button, index) in categoryButton"
                        :key="index"
                        v-ripple.400
                        size="sm"
                        :class="
                        categoryValue === button.value ? 'custom-shadow' : ''
                      "
                        @click="selectedStatus(button.value)"
                        :variant="button.variant"
                        v-text="$t(button.label)"
                    />
                  </div>
                </b-col>
                <b-col
                    cols="12"
                    lg="7"
                    class="justify-content-end mt-1 mt-lg-0 d-flex"
                >
                  <div class="d-flex bigGap w-75 align-items-center">
                    <b-form-input
                        :placeholder="$t(locale.inputPlaceholder)"
                        v-model="queryParams.search"
                    />
                    <div
                        v-ripple.400
                        v-b-modal.modal-customize
                        class="d-flex text-secondaryColor smallGap"
                        role="button"
                    >
                      <img src="@/assets/icon/dashboardAdd.png" width="20px"/>
                      <p class="mb-0" v-text="$t(locale.customize)"/>
                    </div>
                  </div>
                </b-col>

                <!-- Table -->

                <b-col cols="12" class="mt-2">
                  <div class="scrol-area table-responsive">
                    <div
                        v-if="loadingTable"
                        class="text-center text-danger my-2"
                    >
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('Message.loading') }}...</strong>
                    </div>
                    <table v-else class="table b-table table-hover">
                      <thead class="">
                      <tr>
                        <th class="text-left whitespace-nowrap p-2 md:p-0">
                            <span class="text-xs text-gray-400">
                              <b-form-checkbox
                                  v-model="selected"
                                  @change="performAllChecked"
                                  :value="checked"
                                  class="custom-control-primary my-auto"
                              />
                            </span>
                        </th>
                        <th
                            width="140px"
                            v-for="(item, i) in customerTableHeader"
                            :key="i"
                            class="text-center whitespace-nowrap c-p-2 md:p-0"
                        >
                          <div
                              class="flex justify-between gap-5 items-center"
                          >
                              <span class="text-xs text-gray-400">{{
                                  renderName(item)
                                }}</span>
                            <span class="flex flex-col">
                                <svg
                                    @click="sortColumn(item)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 cursor-pointer"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                  <path
                                      fill-rule="evenodd"
                                      d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                      clip-rule="evenodd"
                                  />
                                </svg>
                                <svg
                                    @click="sortColumn(`-${item}`)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 cursor-pointer"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                  <path
                                      fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                          </div>
                        </th>

                        <th class="text-center whitespace-nowrap p-2 md:p-0">
                          <span class="text-xs text-gray-400">Action</span>
                        </th>
                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="data in items" :key="data.uuid">
                        <td>
                            <span>
                              <b-form-checkbox
                                  @change="performSingleChecked"
                                  v-model="data.checkbox"
                              />
                            </span>
                        </td>
                        <td
                            v-for="(item, i) in customerTableHeader"
                            :key="i"
                            style="width: 90px"
                        >
                            <span
                                class="whitespace-nowrap text-sm"
                                v-if="hasInputField.includes(item)"
                            >
                              <b-form-input
                                  size="sm"
                                  style="width: 150px"
                                  @input="
                                  updateFieldValue($event, item, data.uuid)
                                "
                                  v-model="data[item]"
                              />
                            </span>
                          <router-link
                              :to="{
                                name: 'acountingCustomersEdit',
                                params: { id: data.uuid },
                              }"
                              v-else
                          >
                              <span
                                  class="whitespace-nowrap text-sm"
                                  v-if="['tags'].includes(item)"
                              >{{ data[item].join(",") }}</span
                              >
                            <span
                                class="whitespace-nowrap text-sm"
                                v-else-if="['active'].includes(item)"
                            >
                                <b-button
                                    v-ripple.400
                                    size="sm"
                                    :variant="data.active ? 'success' : 'warning'"
                                    v-text="
                                    data.active
                                      ? $t(categoryButton[1].label)
                                      : $t(categoryButton[2].label)
                                  "
                                />
                              </span>
                            <span
                                class="whitespace-nowrap text-sm"
                                v-else-if="['segment'].includes(item)"
                            >{{
                                data[item] && data[item].name
                                    ? data[item].name
                                    : "..."
                              }}</span
                            >

                            <span
                                v-else
                                class="whitespace-nowrap text-sm"
                                :class="{
                                  'text-green-500': data.active === true,
                                  'text-yellow-500': data.active === false,
                                }"
                            >{{ data[item] }}</span
                            >
                          </router-link>
                        </td>
                        <td>
                          <div
                              class="d-flex justify-content-between smallGap align-items-center px-2"
                          >
                            <div
                                class="d-flex gap-2 justify-content-around px-2"
                            >
                                <span
                                    class="cursor-pointer"
                                    @click="openModalDelete(data)"
                                >
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      role="button"
                                      class="text-secondaryColor"
                                      :title="'Delete Customer'"
                                      name="close-circle-outline"
                                  />
                                </span>

                              <span
                                  v-if="!data.active"
                                  @click="openActivityModal('ACTIVATE', data)"
                                  class="cursor-pointer"
                              >
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      role="button"
                                      title="Activate Customer"
                                      class="text-secondaryColor"
                                      name="play"
                                  />
                                </span>

                              <span
                                  v-if="data.active"
                                  @click="openActivityModal('DEACTIVATE', data)"
                                  class="cursor-pointer"
                              >
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      role="button"
                                      title="Deactivate Customer"
                                      class="text-secondaryColor"
                                      name="pause"
                                  />
                                </span>

                              <span @click="openTags(data)">
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      role="button"
                                      class="text-secondaryColor"
                                      :name="
                                      data.tags.length > 0
                                        ? 'tag'
                                        : 'tag-outline'
                                    "
                                  />
                                </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-row
                      class="justify-content-center justify-content-lg-between"
                  >
                    <b-col cols="12" sm="6" class="d-flex align-items-end">
                      <div
                          class="d-flex bigGap justify-content-between align-items-center"
                      >
                        <p class="mb-0 h6" v-text="$t(locale.action)"/>
                        <div class="">
                          <b-form-select
                              id="perPageSelect"
                              size="sm"
                              inline
                              v-model="actionLable"
                          >
                            <b-form-select-option
                                v-for="(option, index) in actionButton"
                                :key="index"
                                :value="option.value"
                                v-text="$t(option.label)"
                            />
                          </b-form-select>
                        </div>

                        <b-button
                            size="sm"
                            variant="primary"
                            :disabled="loadingAction"
                            @click="applyMultiAction(actionLable)"
                            v-text="
                            loadingAction
                              ? $t('Message.loading')
                              : $t(locale.apply)
                          "
                        />

                        <b-button
                            size="sm"
                            variant="primary"
                            @click="openTags(null)"
                            v-text="$t(locale.tag)"
                        />
                      </div>
                    </b-col>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
                    >
                      <paginate
                          v-model="pagination.currentPage"
                          :page-count="pagination.totalCustomers"
                          :click-handler="firePagination"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :page-class="'page-item c-mr-1 border-unset'"
                          prev-link-class="page-link c-mr-1 border-unset"
                          next-link-class="page-link c-mr-1 border-unset"
                          next-class="next-item"
                          prev-class="prev-item"
                          page-link-class="page-link c-mr-1 border-unset"
                          active-class="active c-mr-1 border-unset"
                          :container-class="'pagination  b-pagination'"
                      >
                      </paginate>
                      <!-- <b-pagination
                                                v-model="pagination.currentPage"
                                                :total-rows="
                                                    pagination.totalCustomers
                                                "
                                                :per-page="pagination.perPage"
                                                first-number
                                                last-number
                                                class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
                                                prev-class="prev-item"
                                                next-class="next-item"
                                            >
                                                <template #prev-text>
                                                    <feather-icon
                                                        icon="ChevronLeftIcon" 
                                                        size="18"
                                                    />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon
                                                        icon="ChevronRightIcon"
                                                        size="18"
                                                    />
                                                </template>
                                            </b-pagination> -->

                      <div
                          class="d-flex justify-content-between justify-content-sm-end smallGap align-items-center mt-1 w-100 order-1 order-sm-2"
                      >
                        <p
                            class="mb-0 d-none d-sm-block h6"
                            v-text="$t(locale.showPerPage)"
                        />
                        <div class="">
                          <b-form-select
                              v-model="queryParams.limit"
                              @change="switchLimit"
                              id="perPageSelect"
                              size="sm"
                              inline
                              :options="pageOptions"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </div>

    <!-- Tags -->
    <Modal
        id="modal-tagContract"
        :title="'Tag Selection'"
        :closeEvent="closeModal"
        :hideFooter="true"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'hide-footer': true,
            }"
            :okEvent="okEvent" -->
      <div class="scrol-area" style="height: 200px">
        <div class="grid grid-cols-2 md:grid-cols-3 gap-3">
          <div v-for="data in tagResults" :key="data.uuid">
            <div class="d-flex custom-border bg-white rounded-md c-p-2">
              <b-form-checkbox
                  :value="data.name"
                  v-model="selectedTags"
                  class="text-white"
              >
                {{ data.name }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex smallgap pt-1 justify-content-between">
        <div>
          <b-form-input
              v-model="tagData"
              size="sm"
              :placeholder="$t(locale.addTag)"
          />
        </div>

        <div>
          <b-button
              size="sm"
              @click="saveTag"
              variant="primary"
              v-text="savingTag ? $t('Message.loading') : $t(locale.save)"
          />
        </div>
      </div>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-tagContract')"
        >
          <span v-text="$t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="assignTagData"
        >
          <span
              v-text="
              sendingModalAction ? $t('Message.loading') : $t(locale.save)
            "
          />
        </b-button>
      </div>
    </Modal>

    <!-- <Modal
            id="modal-customize"
            :title="
                $t(locale.modalCustomize, {
                    pageTitle: $t($route.meta.pageTitle),
                })
            "
            :options="{
                size: 'lg',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'hide-footer': true,
                'ok-title': this.$t(locale.update),
            }"
        >
            <div class="scrol-area" style="height: 250px">
                <draggable v-model="fields">
                    <b-card
                        v-for="(data, index) in fields"
                        :key="index"
                        no-body
                        class="border mb-1"
                        style="cursor: grab"
                    >
                        <b-card-text class="p-1 d-flex bigGap">
                            <mdicon
                                :width="20"
                                :height="20"
                                name="flip-to-back"
                            />

                            <p class="mb-0" v-text="$t(data.label)" />
                        </b-card-text>
                    </b-card>
                </draggable>
            </div>

            <div class="pt-1" style="height: 200px">
                <p class="" v-text="$t(locale.chooseColumn)" />
                <b-row class="scrol-area" style="height: 150px">
                    <b-col
                        cols="12"
                        lg="6"
                        v-for="(column, index) in fields"
                        :key="index"
                    >
                        <div class="mt-1">
                            <b-form-checkbox :value="column.key">
                                {{ $t(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <div class="d-flex smallgap pt-1 justify-content-end">
                <div>
                    <b-button variant="primary" v-text="$t(locale.update)" />
                </div>
            </div>
        </Modal> -->

    <Modal
        id="modal-customize"
        :title="
        $t(locale.modalCustomize, {
          pageTitle: $t($route.meta.pageTitle),
        })
      "
        :hideFooter="true"
    >
      <!-- :options="{
                size: 'lg',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'hide-footer': true,
                'ok-title': this.$t(locale.update),
            }" -->
      <div class="scrol-area" style="height: 250px">
        <draggable v-model="customerTableHeader">
          <b-card
              v-for="(data, index) in customerTableHeader"
              :key="index"
              no-body
              class="border mb-1"
              style="cursor: grab"
          >
            <b-card-text class="p-1 d-flex bigGap">
              <mdicon :width="20" :height="20" name="flip-to-back"/>

              <p class="mb-0" v-text="renderName(data)"/>
            </b-card-text>
          </b-card>
        </draggable>
      </div>

      <div class="pt-1">
        <p class="" v-text="$t(locale.chooseColumn)"/>
        <b-row class="scrol-area">
          <b-col
              cols="12"
              lg="6"
              v-for="(column, index) in Object.keys(CUSTOMER_CUSTOMIZE)"
              :key="index"
          >
            <div class="mt-1">
              <b-form-checkbox v-model="customerTableHeader" :value="column">
                {{ renderName(column) }}
                <!-- {{ CUSTOMER_CUSTOMIZE[column] }} -->
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="d-flex smallgap pt-1 justify-content-end">
        <div>
          <b-button
              variant="primary"
              @click="runCustomization"
              v-text="
              sendingModalAction ? $t('Message.loading') : $t(locale.update)
            "
          />
        </div>
      </div>
    </Modal>

    <Modal
        id="modal-multi-action"
        :hideFooter="true"
        :title="choosenData.mainTitle"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ choosenData.banner }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-multi-action')"
        >
          <span v-text="$t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="performModalAction"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') : $t('Message.proceed')"
          />
        </b-button>
      </div>
    </Modal>
    <Modal
        v-if="accessDenied"
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied :message="accessDeniedMessage"/>
    </Modal>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import {
  CUSTOMER_CUSTOMIZATION,
  renderCustomizeName,
} from "./../customization/Constants";
import useAppConfig from "@core/app-config/useAppConfig";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import debounce from "lodash.debounce";
import accessDenied from "@core/components/permission/accessDenied";
import {mapGetters} from "vuex";

export default {
  name: "customerOverviewTab",
  components: {
    paginate: Paginate, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      accessDeniedMessage: null,
      totalCustomer: "",
      loadingState: true,
      accessDenied: true,
      hiddenTable: false,
      activeLabel: "all",
      items: [],
      customerTableHeader: [],
      hasInputField: ["email"],
      choosenData: {},
      loadingTable: true,
      CUSTOMER_CUSTOMIZE: {...CUSTOMER_CUSTOMIZATION},
      customerCustomization: [],
      actionLable: "",
      categoryValue: "all",
      queryParams: {
        active: "all",
        limit: 50,
      },
      pageOptions: [50, 100, 150, 200],
      pagination: {
        currentPage: 1,
        totalCustomers: 0,
        perPage: 20,
      },
      selected: false,
      checked: "selected",
      actionButton: [
        {
          label: "CustomersCard.enabled",
          variant: "success",
          value: "True",
        },
        {
          label: "CustomersCard.inactive",
          variant: "warning",
          value: "False",
        },
      ],
      categoryButton: [
        {
          label: "CustomersCard.all",
          variant: "primary",
          value: "all",
        },
        {
          label: "CustomersCard.enabled",
          variant: "success",
          value: true,
        },
        {
          label: "CustomersCard.inactive",
          variant: "warning",
          value: false,
        },
      ],
      fields: [
        {
          key: "checkbox",
          label: "CustomersCard.table.checkbox",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "50px",
          },
        },
        {
          key: "customer_number",
          label: "CustomersCard.table.custNumber",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "150px",
          },
        },
        {
          key: "name",
          label: "CustomersCard.table.name",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },
        {
          key: "organisation_number",
          label: "CustomersCard.table.personalNumber",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "130px",
          },
        },
        {
          key: "zipCode",
          label: "CustomersCard.table.zipCode",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },
        {
          key: "city",
          label: "CustomersCard.table.city",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },
        {
          key: "country",
          label: "CustomersCard.table.country",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },
        {
          key: "email",
          label: "CustomersCard.table.email",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "130px",
          },
        },
        {
          key: "phone",
          label: "CustomersCard.table.phone",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },
        {
          key: "status",
          label: "CustomersCard.table.status",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },

        {
          key: "action",
          label: "CustomersCard.table.action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
      ],

      locale: {
        addCustomer: "CustomersCard.addCustomer",
        customize: "CustomersCard.customize",
        inputPlaceholder: "CustomersCard.inputPlaceholder",
        showPerPage: "golfRelatedCard.showPerPage",
        tag: "CustomersCard.tag",
        apply: "golfRelatedCard.apply",
        action: "golfRelatedCard.action",
        customize: "CustomersCard.customize",
        modalCustomize: "customizeColumn",
        update: "UsersCard.update",

        tagContract: "contractsCard.tagContract",
        save: "save",
        addTag: "contractsCard.addTag",
      },
      tagData: "",
      savingTag: false,
      tagResults: [],
      selectedTags: [],
      sendingModalAction: false,
      loadingAction: false,
    };
  },

  watch: {
    "queryParams.search": {
      handler: debounce(function () {
        this.getCustomers(false);
      },1500),
    },
  },
  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    features() {
    return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    closeModal() {
    },
    selectedRow(value) {
      this.$router.push({
        name: "acountingCustomersEdit",
        params: {id: value.id},
      });
    },
    statusColor(status) {
      return status.value === "Enabled" ? "success" : "warning";
    },

    switchLimit(value) {
      this.queryParams.limit = value;
      this.getCustomers();
    },

    selectedStatus(value) {
      this.categoryValue = value;
      if (this.search == "") {
        delete this.queryParams.search;
      }
      this.queryParams.active = value;
      this.getCustomers(false);
    },
    renderTable(data) {
      this.items = data.map((item) => {
        return {
          ...item,
          id: item.uuid,
          checkbox: false,
          status: item.active ? "Enabled" : "Inactive",
        };
      });
    },
    firePagination(number) {
      this.pagination.currentPage = number;
      this.queryParams.page = number;
      this.getCustomers(true);
    },
    getCustomers(afterLoad) {
      this.loadingTable = true;
      this.$useJwt.getCustomers({params: this.queryParams}).then((res) => {
        const {data} = res.data;

        this.renderTable(data);

        if (!afterLoad) {
          this.loadingState = false;
          this.hiddenTable = true;
        }
        this.loadingTable = false;
        this.pagination = {
          totalCustomers: res.data.total_pages,
          perPage: res.data.limit,
        };

        this.totalCustomer = data.length;
      }).catch(err => {
        this.loadingTable = false;
        if (err.response.status === 403) {
          this.accessDeniedMessage = err.response.data.message
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      });
    },
    performAllChecked(e) {
      this.items.forEach((element) => {
        element.checkbox = this.selected === "selected" ? true : false;
      });
    },
    performSingleChecked(e) {
    },
    updateFieldValue: debounce(function (event, item, uuid) {
      if (event) {
        this.updateField(event, item, uuid);
      }
    }, 350),
    saveTag() {
      if (this.tagData !== "") {
        this.savingTag = true;
        const payload = {
          type: "CUSTOMER",
          name: this.tagData,
        };
        this.$useJwt
            .customTags({URL: "/", method: "post"}, payload)
            .then((res) => {
              this.savingTag = false;
              this.getTags();
              this.tagData = "";
            })
            .catch((err) => {
              this.savingTag = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    renderName(key) {
      return renderCustomizeName(this.getLang, key, CUSTOMER_CUSTOMIZATION);
    },
    getCustomizeHeader() {
      this.$useJwt
          .customization({URL: "customer", method: "get"})
          .then((res) => {
            const fields = [
              {
                key: "checkbox",
                label: "articleCard.table.articleNumber",
                tdClass: "bTableThStyle",
                thStyle: {
                  padding: "0.72rem 10px",
                  width: "50px",
                },
              },
              {
                key: "action",
                label: "CustomersCard.table.action",
                tdClass: "bTableThStyle",
                thStyle: {
                  padding: "0.72rem 10px",
                  width: "100px",
                },
              },
            ];
            if (res.data.data.length > 0) {
              this.customerTableHeader = res.data.data;
              res.data.data.map((el, i) => {
                const obj = {
                  key: el,
                  label: el,
                  tdClass: "bTableThStyle",
                  thStyle: {
                    padding: "0.72rem 10px",
                    width: "140px",
                  },
                };
                fields.splice(parseInt(i + 1), 0, obj);
              });
            } else {
              Object.keys(CUSTOMER_CUSTOMIZATION).forEach((el) => {
                this.customerTableHeader.push(el);
              });
            }
            this.fields = fields;
          });
    },
    runCustomization() {
      if (this.customerTableHeader.length > 0) {
        this.sendingModalAction = true;
        const payload = {
          columns: this.customerTableHeader,
        };

        this.$useJwt
            .customization({URL: "customer", method: "put"}, payload)
            .then((res) => {
              this.getCustomizeHeader();
              this.sendingModalAction = false;
              this.getCustomers();
              this.$bvModal.hide("modal-customize");
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data.message,
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    getTags() {
      this.$useJwt
          .customTags({URL: "?type=CUSTOMER", method: "get"})
          .then((res) => {
            this.tagResults = res.data.results;
          });
    },
    applyMultiAction(value) {
      if (value) {
        const payload = {
          selected: [],
          active: value,
        };
        const filter = this.items.filter((el) => el.checkbox);
        if (filter.length > 0) {
          this.loadingAction = true;
          filter.map((el) => {
            payload.selected.push(el.uuid);
          });

          this.$useJwt
              .customCustomers({URL: "/status", method: "put"}, payload)
              .then((res) => {
                this.actionLable = "";
                this.loadingAction = false;
                this.getCustomers(false);
                // this.getContracts({ URL : '', method : 'get' });
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data.message,
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.loadingAction = false;
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data[this.message],
                    "AlertTriangleIcon",
                    "danger"
                );
              });
        } else {
          this.popupMsg(
              this.$t("Message.Failed"),
              "Select articles to apply action",
              "AlertTriangleIcon",
              "danger"
          );
        }
      }
    },
    assignTagData() {
      const payload = {
        tags: this.selectedTags,
        selected: [],
      };
      const filter = this.items.filter((el) => el.checkbox);
      if (filter.length > 0) {
        this.sendingModalAction = true;
        filter.map((el) => {
          payload.selected.push(el.uuid);
        });

        this.$useJwt
            .customCustomers({URL: "/bulktag", method: "put"}, payload)
            .then((res) => {
              this.selectedTags = [];
              this.sendingModalAction = false;
              this.$bvModal.hide("modal-tagContract");
              this.getCustomers(true);
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.message],
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    openActivityModal(mode, data) {
      this.choosenData = data;
      this.choosenData["banner"] =
          mode === "ACTIVATE"
              ? "Är du säker på att du vill aktivera denna kund?"
              : "Är du säker på att du vill inaktivera denna kund?";
      this.choosenData["mainTitle"] =
          mode === "ACTIVATE" ? "Aktivera kund" : "Inaktivera kund";
      this.choosenData["mode"] = mode;
      this.$bvModal.show("modal-multi-action");
    },
    openModalDelete(item) {
      this.choosenData = item;
      this.choosenData["banner"] =
          "Är du säker på att du vill radera denna kund?";
      this.choosenData["mainTitle"] = "Radera kund";
      this.choosenData["mode"] = "DELETE";
      this.$bvModal.show("modal-multi-action");
    },
    performModalAction() {
      this.sendingModalAction = true;
      if (this.choosenData.mode === "ACTIVATE") {
        this.toggleActivity(true, this.choosenData, false);
      } else if (this.choosenData.mode === "DEACTIVATE") {
        this.toggleActivity(false, this.choosenData, false);
      } else {
        this.toggleActivity(false, this.choosenData, true);
      }
      this.sendingModalAction = false;
      this.$bvModal.hide("modal-multi-action");
    },
    sortColumn(item) {
      this.queryParams.ordering = item;
      delete this.queryParams.search;
      this.getCustomers();
    },
    toggleActivity(value, data, remove = false) {
      let urlMode = {URL: "", method: "put"};
      let payload = {
        active: value,
      };

      if (remove) {
        urlMode = {URL: `/${data.uuid}`, method: "delete"};
        payload = {};
      } else {
        urlMode = {URL: `/${data.uuid}`, method: "put"};
      }

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: this.$t("Message.wait"),
          variant: "warning",
          // text: this.$t('Message.subscriptionProcess'),
          waiting: true,
        },
      });

      this.$useJwt
          .customCustomers(urlMode, payload)
          .then((res) => {
            this.getCustomers();
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.loadingAction = false;
            if (err.response.data.message) {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data.message,
                  "AlertTriangleIcon",
                  "danger"
              );
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
    updateField(value, field, uuid) {
      const payload = {};
      payload[field] = value;

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: this.$t("Message.wait"),
          variant: "warning",
          // text: this.$t('Message.subscriptionProcess'),
          waiting: true,
        },
      });

      this.$useJwt
          .customCustomers({URL: `/${uuid}`, method: "put"}, payload)
          .then((res) => {
            this.getCustomers();
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            if (err.response.data.message) {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data.message,
                  "AlertTriangleIcon",
                  "danger"
              );
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
    openTags(item = null) {
      if (item) {
        item.checkbox = true;
        this.selectedTags = item.tags;
        this.$bvModal.show("modal-tagContract");
      } else {
        const filter = this.items.filter((el) => el.checkbox);
        if (filter.length > 0) {
          this.$bvModal.show("modal-tagContract");
        }
      }
    },
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getCustomizeHeader();
    this.getCustomers();
    this.getTags();

  },
};
</script>

import { debounce } from "lodash";
<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.scrol-area {
  overflow: auto;
  scrollbar-color: $primary transparent;
}

.table th {
  padding: 0.4rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody,
td {
  padding: 0.4rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
